import { defaultLocale, Locale, Translation } from "@model/constants";
import { Appointment } from "@model/entities";
import { Labels } from "@model/models";
import date from "@model/utils/date";

// This is an ordered list of statuses
const _appointmentStatuses = [
    "pending",
    "confirmed",
    "prepaid",
    "arrived",
    "consulting",
    "treatment",
    "payment",
    "payment-processing",
    "dispense",
    "completed",
    "cancelled",
] as const;

export type AppointmentStatus = typeof _appointmentStatuses[number];

/**
 *  Enum for ease of use
 *  @see _appointmentStatuses has to be the single source of truth
 *  */
export enum AppointmentStatusEnum {
    Pending = "pending",
    Confirmed = "confirmed",
    Prepaid = "prepaid",
    Arrived = "arrived",
    Consulting = "consulting",
    Treatment = "treatment",
    Payment = "payment",
    PaymentProcessing = "payment-processing",
    Dispense = "dispense",
    Completed = "completed",
    Cancelled = "cancelled",
}

export const appointmentStatusMap: Labels<AppointmentStatusEnum, {
    textColor: string,
    translation: Translation,
    isPaid?: boolean,
    whitelist: AppointmentStatus[],
}> = {
    pending: {
        label: "掛號",
        color: "#4fe74a",
        meta: {
            textColor: "#000",
            translation: {
                zh: "掛號",
                en: "Pending",
            },
            whitelist: [
                'confirmed',
                'arrived',
                'cancelled',
            ],
        }
    },
    confirmed: {
        label: "確認",
        color: "#4fe74a",
        show: false,
        meta: {
            textColor: "#000",
            translation: {
                zh: "確認預約",
                en: "Confirmed",
            },
            whitelist: [
                'pending',
                'prepaid',
                'cancelled',
            ],
        }
    },
    prepaid: {
        label: "預繳",
        color: "#4fe74a",
        show: false,
        meta: {
            textColor: "#000",
            translation: {
                zh: "預繳",
                en: "Prepaid",
            },
            whitelist: [
                'pending',
                'cancelled',
            ],
        }
    },
    arrived: {
        label: "到診",
        color: "rgb(241, 152, 0)",
        meta: {
            textColor: "#000",
            translation: {
                zh: "侯診",
                en: "Arrived",
            },
            whitelist: [
                'pending',
                'prepaid',
                'confirmed',
                'cancelled',
                'consulting',
            ],
        }
    },
    consulting: {
        label: "診症中",
        color: "rgb(255, 195, 90)",
        show: false,
        meta: {
            textColor: "#000",
            translation: {
                zh: "診症中",
                en: "Consulting",
            },
            whitelist: [
                'arrived',
                'consulting',
                'cancelled',
            ],
        }
    },
    treatment: {
        label: "治療室",
        color: "rgb(104, 153, 97)",
        show: true,
        meta: {
            textColor: "#000",
            translation: {
                zh: "治療室",
                en: "Treatment",
            },
            whitelist: [
                'arrived',
                'consulting',
                'treatment',
                'payment',
                'cancelled',
            ],
        }
    },
    payment: {
        label: "收費",
        color: "#dc99e1",
        meta: {
            textColor: "#000",
            translation: {
                zh: "收費",
                en: "Payment",
            },
            whitelist: [
                'pending',
                'arrived',
                'consulting',
                'treatment',
                'payment',
                'payment-processing',
                // Feature: Payment Revert
                'dispense',
                'cancelled',
                'completed',
            ],
        }
    },
    "payment-processing": {
        label: "收費中",
        color: "rgb(176, 105, 181)",
        show: false,
        meta: {
            textColor: "#000",
            translation: {
                zh: "收費中",
                en: "Payment Processing",
            },
            whitelist: [
                'payment',
                'payment-processing',
                'cancelled',
            ],
        }
    },
    dispense: {
        label: "配藥",
        color: "#95fdff",
        meta: {
            textColor: "#000",
            translation: {
                zh: "配藥",
                en: "Dispense",
            },
            isPaid: true,
            whitelist: [
                'payment',
                'payment-processing',
                'dispense',
                'cancelled',
            ],
        }
    },
    completed: {
        label: "完成",
        color: "#f1f1f1",
        meta: {
            textColor: "#000",
            translation: {
                zh: "已完成",
                en: "Completed",
            },
            isPaid: true,
            whitelist: [
                'arrived',
                'consulting',
                'payment',
                'payment-processing',
                'dispense',
                'cancelled',
            ],
        }
    },
    cancelled: {
        label: "取消",
        color: "#ff1904",
        show: false,
        meta: {
            textColor: "#000",
            translation: {
                zh: "已取消",
                en: "Cancelled",
            },
            whitelist: _appointmentStatuses.filter(x => x !== 'cancelled'),
        }
    },
};

export const validateAppointmentStatusChange = (options: { from: AppointmentStatus, to: AppointmentStatus }): boolean => {
    return appointmentStatusMap[options.to]?.meta.whitelist.includes(options.from);
}

export const translateAppointmentStatus = (type: AppointmentStatus, locale: Locale = defaultLocale) => {
    return appointmentStatusMap[type].meta.translation[locale];
}

export const isBeforeOrEqualToAppointmentStatus = (status: AppointmentStatus, toStatus: AppointmentStatus) => {
    return _appointmentStatuses.indexOf(status) <= _appointmentStatuses.indexOf(toStatus);
}

export const isAfterAppointmentStatus = (status: AppointmentStatus, toStatus: AppointmentStatus) => {
    return _appointmentStatuses.indexOf(status) > _appointmentStatuses.indexOf(toStatus);
}

export const isAfterOrEqualToAppointmentStatus = (status: AppointmentStatus, toStatus: AppointmentStatus) => {
    return _appointmentStatuses.indexOf(status) >= _appointmentStatuses.indexOf(toStatus);
}

export const paidAppointmentStatuses = Object.keys(appointmentStatusMap).filter(s => appointmentStatusMap[s].meta?.isPaid ?? false);

/**
 * Get list of all statuses after the given status (inclusive)
 */
export const getListOfAppointmentStatusAfter = (status: AppointmentStatus, includeCancelled = false): AppointmentStatus[] => {
    return _appointmentStatuses.filter(x => isAfterOrEqualToAppointmentStatus(x, status) && (includeCancelled || x !== 'cancelled'));
}

export const getAppointmentStatusSortFunction = <T extends { status: AppointmentStatus }>(
    priortized?: AppointmentStatus,
    datetimeKey: keyof Appointment = 'arrived_at',
): ((a: T, b: T) => number) => {
    const front = -1;
    const back = 1;
    return (a: T, b: T) => {
        if (a.status === priortized && b.status !== priortized) {
            return front;
        }

        if (b.status === priortized && a.status !== priortized) {
            return back;
        }

        if (a.status !== b.status) {
            return _appointmentStatuses.indexOf(a.status) - _appointmentStatuses.indexOf(b.status);
        }

        return date.isDayBefore(a[datetimeKey], b[datetimeKey]) ? front : back;
    }
}