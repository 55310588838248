import { useCache } from "@/hooks/useCache";
import { UserService } from "@/services";
import { initEventEmitter } from "./events";

export * from './axios-lock';
export * from './admin-herb.service';
export * from './user/user.service';
export * from './user/user-group.service';
export * from './organization.service';
export * from './clinic.service';
export * from './doctor.service';
export * from './doctor-prescription.service';
export * from './patient.service';
export * from './pricing.service';
export * from './appointment.service';
export * from './herb.service';
export * from './herb-brand.service';
export * from './herb-function.service';
export * from './herb-unit.service';
export * from './herb-transform.service';
export * from './notification.service';
export * from './consultation.service';
export * from './consultation-treatment.service';
export * from './prescription.service';
export * from './printing.service';
export * from './payment.service';
export * from './setting.service';
export * from './audit.service';
export * from './dispense.service';
export * from './report.service';
export * from './prescription-delivery.service';
export * from './payment-pricing-item.service';
export * from './payment-pricing-discount.service';
export * from './payment-pricing-offer.service';
export * from './admin-herb-stock.service';
export * from './admin-herb-stock-in.service';
export * from './admin-herb-stock-order.service';
export * from './admin-herb-stock-check.service';
export * from './admin-herb-stock-adjust.service';
export * from './settlement.service';
export * from './package.service';
export * from './pricing-item-library.service';
export * from './file.service';
export * from './auto-reminder.service';
export * from './events/confirm-unload.decorator';
export * from './events/listen-event.decorator';

let time = new Date().getTime();
const { wsCache } = useCache('localStorage');

export const initServices = async () => {
    initEventEmitter();

    // 2024-09-07 Remove Idle logout
    // Idle reload
    // if (process.env.NODE_ENV !== 'development') {
    const resetTimer = (e) => {
        time = new Date().getTime();
        // console.log('resetTimer', time);
    }

    window.addEventListener('load', resetTimer, true);
    // window.addEventListener('mousemove', resetTimer, true);
    window.addEventListener('mousedown', resetTimer, true);
    window.addEventListener('touchstart', resetTimer, true);
    window.addEventListener('touchmove', resetTimer, true);
    window.addEventListener('click', resetTimer, true);
    window.addEventListener('keydown', resetTimer, true);
    window.addEventListener('scroll', resetTimer, true);
    window.addEventListener('wheel', resetTimer, true);

    const refresh = () => {
        // console.log('Logout Tick remaining:', 7200000 - (new Date().getTime() - time));
        if (new Date().getTime() - time >= 28800000) {
            if (wsCache.get('isLoggedIn')) {
                if ((window as Window & { gtag?: Function }).gtag) {
                    // console.log('gtag');
                    const user = wsCache.get('user');
                    (window as Window & { gtag?: Function }).gtag('event', 'user_activity', {
                        'event_category': 'session', 
                        'event_label': 'idle_logout',
                        'user_id': user?.id,
                        'user_name': user?.name,
                    });
                }
                UserService.logout();
                setTimeout(() => window.location.reload(), 2000);
            }
        } else {
            setTimeout(refresh, 1000);
        }
    }
    setTimeout(refresh, 1000);
    // }
}